import { createApp } from 'vue'
import { createGtm } from '@gtm-support/vue-gtm';
import App from './App.vue'
import router from './router'
import store from './store';
import './styles/main.scss';  
import './axios/index.js'; 

createApp(App).use(store).use(router).use(
    createGtm({
        id: 'GTM-WC4KPLPK',
        // debug: true, // Whether or not display console logs debugs (optional)
        vueRouter: router,
        // ignoredViews: ['homepage'] // If router, you can exclude some routes name (case insensitive) (optional)
    })
).mount('#app')
 