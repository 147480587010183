import { createRouter, createWebHistory } from 'vue-router';

import store from '@/store';

const routes = [
  {
    path: '/',
    name: 'interfaces',
    component: () => import('../views/Main/BaseTemplate.vue'),
    children: [
      //Pagina de inicio
      {
        path: '',
        name: 'landing',
        meta: { title: 'Inicio - HANDY APP' },
        component: () => import('../components/landing/Landing.vue')
      },
      //Sección de sectores
      {
        name: 'subsectorsList',
        path: 'subsector/:id',
        meta: { title: 'Subsectores - HANDY APP' },
        component: () => import('../components/sectors/SubsectorsList.vue'),
      },
      {
        name: 'descarga',
        path: 'descargar',
        meta: { title: 'Descargar - HANDY APP' },
        component: () => import('@/components/download/DownloadInterface.vue'),
      },
      //Sección de autenticación
      {
        path: 'registro',
        name: 'userRegister',
        meta: { title: 'Registro - HANDY APP' },
        component: () => import('../components/register/UserRegister.vue')
      },
      {
        path: 'login',
        name: 'userLogin',
        meta: { title: 'login - HANDY APP' },
        component: () => import('../components/login/UserLogin.vue')
      },
      //Sección de recuperación de contraseña
      {
        path: 'recuperar-contrasena',
        name: 'recover',
        meta: { title: 'Recuperar contraseña - HANDY APP' },
        component: () => import('../components/user/UserRecoverPassword.vue')
      },
      {
        path: 'cambiar-contrasena',
        name: 'change',
        meta: { title: 'Cambiar contraseña - HANDY APP', requiresAuth: true },
        component: () => import('../components/user/UserChangePassword.vue')
      },
      //Sección de listado de ubicaciones
      {
        path: 'ubicaciones/:id',
        name: 'locations',
        meta: { title: 'Listas - HANDY APP' },
        component: () => import('../components/lists/LocationsList.vue')
      },
      {
        path: 'subsector/:subsector/ubicaciones/:id',
        name: 'subsectorLocations',
        meta: { title: 'Listas - HANDY APP' },
        component: () => import('../components/lists/LocationsList.vue')
      },
      {
        path: 'ubicaciones/filtros/:sector',
        name: 'filters',
        meta: { title: 'Filtros - HANDY APP' },
        component: () => import('../components/lists/ListFilters.vue')
      },
      {
        path: 'ubicacion/:id',
        name: 'details',
        meta: { title: 'Detalles - HANDY APP' },
        component: () => import('../components/Details/LocationDetails.vue')
      },
      {
        name: 'mapView',
        path: 'ubicaciones/:sector/mapa',
        component: () => import('../components/map/Map.vue'),
        meta: { title: 'Mapa - HANDY APP' },
      },
      {
        name: 'mapSubsectorView',
        path: '/subsector/:subsector/ubicaciones/:sector/mapa',
        component: () => import('../components/map/Map.vue'),
        meta: { title: 'Mapa - HANDY APP' },
      },
      {
        name: 'search',
        path: 'busqueda/:query',
        meta: { title: 'Búsqueda - HANDY APP' },
        component: () => import('../components/lists/SearchedLocationsList.vue'),
      },
      //Sección de usuario
      {
        path: 'configuracion',
        name: 'config',
        meta: { title: 'Configuración - HANDY APP', requiresAuth: true },
        component: () => import('../components/user/UserConfig.vue')
      },
      {
        path: 'favoritos',
        name: 'favorites',
        meta: { title: 'Favoritos - HANDY APP', requiresAuth: true },
        component: () => import('../components/user/UserFavorites.vue')
      },
      //Sección estaticos
      {
        name: 'staticDetails',
        path: 'estatico/:id',
        meta: { title: 'Estático detalles - HANDY APP' },
        component: () => import('../components/static/StaticDetails.vue'),

      },
      {
        name: 'staticList',
        path: 'estaticos/:id',
        meta: { title: 'Estáticos - HANDY APP' },
        component: () => import('../components/static/StaticList.vue'),
      },
      //Sección participa
      {
        path: 'participa',
        name: 'participate',
        meta: { title: '¡Participa! - HANDY APP' },
        component: () => import('../components/participate/Participate.vue')
      },
      {
        path: 'campana/:id',
        name: 'campaign',
        meta: { title: 'Campaña - HANDY APP' },
        component: () => import('../components/campaign/CampaignDetails.vue')
      },

      /*       {
              path:'rgpd/:id',
              name: 'rgpd',
              meta: {title: 'rgpd - HANDY APP'},
              component: () => import('../components/static/rgpd.vue')
            }, */


      {
        path: 'mis-participaciones',
        name: 'participations',
        meta: { title: 'Participaciones - HANDY APP', requiresAuth: true },
        component: () => import('@/components/user/UserParticipations.vue')

      },


      {
        path: 'politica-de-privacidad',
        name: 'privacyPolicy',
        meta: { title: 'Política de privacidad - HANDY APP' },
        component: () => import('@/components/rgpd/fortuna/PrivacyPolicy.vue')

      },

      {
        path: 'condiciones-de-uso',
        name: 'termsOfService',
        meta: { title: 'Condiciones de uso - HANDY APP' },
        component: () => import('@/components/rgpd/fortuna/TermsOfService.vue')

      },

      {
        path: 'politica-de-cookies',
        name: 'cookiesPolicy',
        meta: { title: 'Política de Cookies - HANDY APP' },
        component: () => import('@/components/rgpd/fortuna/CookiesPolicy.vue')

      },

      {
        path: 'aviso-legal',
        name: 'legalNotice',
        meta: { title: 'Aviso Legal- HANDY APP' },
        component: () => import('@/components/rgpd/fortuna/LegalNotice.vue')

      },


      {
        path: 'bases-legales-comprareloj-2024',
        name: 'campaignOneBases',
        meta: { title: 'Bases legales Comprareloj 2024' },
        component: () => import('@/components/rgpd/fortuna/CampaignOneBases.vue')

      }, 

      {
        name: 'error',
        path: '/:pathMatch(.*)*',
        meta: { title: '404 - HANDY APP' },
        component: () => import('@/components/errors/404.vue'),
      },
    ]
  },
];



const router = createRouter({

  scrollBehavior(to) {
    if (to.name !== 'landing') {
      // always scroll to top for routes other than 'landing'
      return { top: 0 };
    }
  },

  history: createWebHistory(process.env.BASE_URL),
  routes
});



router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'HANDY APP';
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    if ((to.path === '/login' || to.path === '/registro') && store.getters.isAuthenticated) {
      next({ path: '/' });
    } else {
      next();
    }

  }


});

export default router;
